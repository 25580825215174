import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { Layout } from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { theme } from "../theme/theme";
import { useTagStringToArray } from "../hooks/useTagStringToArray";
import { WorkList } from "../components/WorkList";
import Fade from "react-reveal/Fade";

const WorkPage = ({ pageContext }) => {
  const { page, allWorks } = pageContext.data;

  const tags = useTagStringToArray(page.tag);

  console.log(page);

  const [showProjectInfo, setShowProjectInfo] = useState(
    page.gallery.length === 0
  );

  return (
    <>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Layout>
        <Article>
          <section className="Hero flex col align-center">
            <div className="flex container padding col">
              <h1>{page.client}</h1>
              <GatsbyImage
                className="heroImg"
                image={page.image?.gatsbyImageData}
                alt={page.image?.alt || "Article Image"}
              />
              <div className="flex">
                <button
                  type="button"
                  className="ProjectInfoBtn flex align-center"
                  onClick={() => setShowProjectInfo(!showProjectInfo)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={`
                      transition: all 0.25s;
                      ${showProjectInfo &&
                      `
                        transform: rotate(45deg);`}
                    `}
                  >
                    <path
                      d="M20 9.375H10.625V0H9.375V9.375H0V10.625H9.375V20H10.625V10.625H20V9.375Z"
                      fill="black"
                    />
                  </svg>
                  Project information
                </button>
              </div>
              <div className="InformationCont">
                <div
                  className={`Information flex${
                    showProjectInfo ? " show" : ""
                  }`}
                >
                  <div className="flex col">
                    <span>{page.year}</span>
                    {tags.map((tag) => (
                      <span>{tag}</span>
                    ))}
                  </div>
                  <div className="flex">
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: page.information }}
                    />
                  </div>
                </div>
              </div>
              {page.collage.length === 0 && (
                <div className="Gallery">
                  {page.gallery.map((i, index) => {
                    return (
                      <Fade>
                        <GatsbyImage
                          className="galleryImg"
                          image={i.gatsbyImageData}
                          alt={i.alt || "Image"}
                        />
                      </Fade>
                    );
                  })}
                </div>
              )}
              {page.collage.map((row) => {
                return (
                  <div className="CollageRow flex">
                    {row.images.map((img, index) => (
                      <Fade delay={index * 100}>
                        <GatsbyImage
                          className="CollageImg"
                          image={img.gatsbyImageData}
                          alt={img.alt || "Image"}
                        />
                      </Fade>
                    ))}
                  </div>
                );
              })}
            </div>
          </section>
        </Article>
        <div
          className="row padding container"
          css={`
            h3 {
              font-size: 20px;
              font-weight: 500;
              width: 100%;
              padding-top: 15px;
              border-top: 1px solid #000;
              @media (max-width: 600px) {
                border-top: none;

                font-size: 16px;
              }
            }
          `}
        >
          <h3>More Projects</h3>
        </div>
        <WorkList data={allWorks.edges} />
      </Layout>
    </>
  );
};

export default WorkPage;

const Article = styled.article`
  padding-top: 30px;
  padding-bottom: 60px;
  h1 {
    margin-bottom: 20px;
  }
  .ProjectInfoBtn {
    font-size: 20px;
    height: 34px;
    margin-top: 20px;
    margin-bottom: 40px;
    color: #000 !important;
    svg {
      margin-right: 10px;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  .galleryImg {
    margin-top: 20px;
  }
  .InformationCont {
    overflow: hidden;
  }
  .Information {
    a::before {
      position: absolute;
      content: attr(data-content);
      color: white;
      clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
      transition: clip-path 275ms ease;
      background: black;
    }
    a:hover::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: -100%;
    transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    &.show {
      margin-top: 0;
    }
    @media (max-width: 600px) {
      margin-top: 0;
    }
    @media (max-width: 600px) {
      flex-direction: column;
    }
    > div:first-child {
      width: 25%;
      min-width: 160px;
      margin-bottom: 40px;
      @media (max-width: 600px) {
        margin-top: 40px;
        width: 100%;
      }
    }
    .content {
      max-width: 534px;
    }
  }
  .CollageRow {
    gap: 20px;
    margin-bottom: 20px;
  }
`;
